import { withPrefix } from "gatsby"

import React from "react"

import Layout from "../components/layout"
import HeaderWhitenav from "../components/header-whitenav"
import SEO from "../components/seo";

const Kontakt = () => (
  <Layout>
    <HeaderWhitenav />
    <SEO
      title={'Kontakt | Lekarze specjaliści Świdnica'}
      description={'Nasz zespół bardzo chętnie odpowie na wszystkie pytania. Zapraszamy do Dent&Beauty.'}
    />
    <div id="pagewrapper">
      <section className="section padding-top60 wow fadeInUp"
               style={{ visibility: 'hidden'}}>
        <div className="row transform-none">
          <div className="large-5 medium-12 small-12 columns">
            <div className="text-content text-padding">
              <h3 className="border-bottom">Skontaktuj się z nami</h3>
              <div className="font-mobile">
                <p>Nasz zespół bardzo chętnie odpowie na wszystkie pytania. Zachęcamy do kontaktu drogą telefoniczną,
                  mailową,
                  a także zapraszamy bezpośrednio do naszej kliniki. Jesteśmy do Twojej&nbsp;dyspozycji.</p>
              </div>
            </div>
          </div>
          <div className="large-7 medium-12 small-12 columns show-for-large">
            <div className="photobg kontakt-photo shadow"
                 style={{ backgroundImage: `url(${withPrefix('images/kontakt-image.jpg')}`}} />
          </div>
        </div>
        <div className="row transform-top show-for-large">
          <div className="large-5 large-push-7 medium-12 small-12 columns">
            <div className="text-content text-padding-2">
              <div className="display-table" data-equalizer-watch="kontakt">
                <div className="display-table-cell">
                  <h6 className="margin-0 normal big gray2"><strong>SKONTAKTUJ SIĘ Z NAMI</strong></h6>
                  <h5 className="normal big"><strong>RECEPCJA / ADRES</strong></h5>
                  <p>+48&nbsp;603 631 009<br />
                    <a href="mailto:rejestracja@dentandbeauty.pl">rejestracja@dentandbeauty.pl</a><br />
                    ul. Waleriana Łukasińskiego 24/02<br />
                    Świdnica&nbsp;58-100</p>
                </div>
              </div>
            </div>
          </div>
          <div className="large-7 large-pull-5 medium-12 small-12 columns show-for-large">
            <div className="su-column su-column-size-1-2">
              <div className="su-column-inner su-u-clearfix su-u-trim">
                <div className="su-gmap su-u-responsive-media-yes">
                  <iframe
                    src="//maps.google.com/maps?q=%C5%9Awidnica%2C%20ul.%20Waleriana%20%C5%81ukasi%C5%84skiego%2024%2F02&amp;output=embed"
                    title="" width="100%" height="460px" style={{border: 'none'}}></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row hide-for-large">
          <div className="medium-12 small-12 columns">
            <p className="margin-top40 normal big gray2"><strong>SKONTAKTUJ SIĘ Z NAMI</strong></p>
            <div className="font-mobile margin-bottom40">
              <h5 className="normal big font-18 margin-bottom14"><strong>RECEPCJA / ADRES</strong></h5>
              <p>+48&nbsp;603 631 009<br />
                <a href="mailto:rejestracja@dentandbeauty.pl">rejestracja@dentandbeauty.pl</a><br />
                ul. Waleriana Łukasińskiego 24/02<br />
                Świdnica&nbsp;58-100</p>
            </div>
          </div>
        </div>
        <a
          href="https://www.google.pl/maps/place/Waleriana+%C5%81ukasi%C5%84skiego+24,+%C5%9Awidnica/@50.8549228,16.4862204,13.83z/data=!4m5!3m4!1s0x470fb2b4d6d84d6d:0x74d2a3f6d71ce8de!8m2!3d50.8496496!4d16.4863834?hl=pl"
          className="hide-for-large more-small block text-center margin-bottom40" rel="nofollow">pokaż wskazówki
          dojazdu</a>
      </section>
    </div>
  </Layout>
)

export default Kontakt


